<template>
  <div class="ChangePassword">
    <img
      class="Logo"
      src="@/assets/svg/logos/milva-logo-green.svg" />
    <span
      v-if="firstTimeLogin"
      class="ChangePasswordText">
      {{ mixWB('WELCOME_TO_MILVA') }}
    </span>
    <span
      v-else
      class="ChangePasswordText">
      {{ mixWB('CHANGE_PASSWORD_TO_START_TEXT') }}
    </span>
    <div class="ChangePasswordFormWrap">
      <ChangePasswordForm :onSuccessfullChanged="onSuccessfullChanged" />
    </div>
  </div>
</template>

<script>
import ChangePasswordForm from '@/components/Forms/ChangePasswordForm.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ChangePassword',
  computed: {
    ...mapGetters([
      'currentUser',
    ]),
    firstTimeLogin() {
      return this.currentUser.firstTimeLogin
    },
  },
  methods: {
    onSuccessfullChanged() {
      if (this.firstTimeLogin) {
        this.$store.dispatch('updateUser', { user: { id: this.currentUser.id, firstTimeLogin: false } })
      }
      this.$router.push({ name: 'AllScreenings' })
    },
  },
  components: {
    ChangePasswordForm,
  },
}
</script>

<style lang="stylus" scoped>
  .ChangePasswordFormWrap
    width 100%
    max-width 80%
    margin-bottom 30px
  .ChangePassword
    width 100%
    height 100vh
    display flex
    flex-direction column
    align-items center
    padding-top 100px

  .Logo
    max-width 150px
    margin-bottom 30px
    margin-left 30px

  .ChangePasswordText
    margin-bottom 30px

</style>

<style lang="stylus">
  .PageSignIn
    padding-bottom 0
</style>
