import { ENV } from '@/globals/javascript/_util/env'
import { mixWB } from '@/globals/javascript/_util/mixins'

const errorMessages = {
  'auth/email-already-exists': () => mixWB('AUTH_EMAIL_ALREADY_EXISTS'),
  'auth/invalid-email': () => mixWB('AUTH_INVALID_EMAIL'),
  'auth/invalid-password': () => mixWB('AUTH_INVALID_PASSWORD'),
  'auth/wrong-password': () => mixWB('AUTH_WRONG_PASSWORD'),
  'auth/user-disabled': () => mixWB('THIS_USER_IS_NO_LONGER_ACTIVE'),
}

export const getErrorMessage = (code, message) => {
  if (ENV.isDevelopment || ENV.isStage) {
    // eslint-disable-next-line no-console
    console.error({ code, message })
  }

  return errorMessages[code]?.() ?? message
}
